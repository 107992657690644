<template>
  <div class="newsInfoBox">
    <h2 v-if="list.length">新闻资讯</h2>
    <ul class="ul">
      <li @click="handleJump(item)" class="pc" v-for="(item, index) in list.slice(0, 3)" :key="index">
        <div class="item" :class="flag ? 'scaleImg' :''">
          <img :src="item.coverUrl" alt="">
          <div class="bottom">
            <h3 class="text-ellipsis-2">{{ item.title }}</h3>
            <p>{{ item.date }}</p>
          </div>
        </div>
      </li>
      <li @click="handleJump(item)" class="h5" v-for="(item, index) in getList.slice(0, 3)" :key="index + key">
        <div class="item">
          <img :src="item.coverUrl" alt="">
          <div class="bottom">
            <h3 class="text-ellipsis-2">{{ item.title }}</h3>
            <p>{{ item.date }}</p>
          </div>
        </div>
      </li>
    </ul>
    <div v-if="list.length && list.length>3" class="moreBtn" @click="handleCheckMore">
      <!-- <div v-if="list.length" class="moreBtn"  @click="handleCheckMore"> -->
      <span>查看更多</span>
      <div class="circle">
        <img src="@/assets/home/arrow-right.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import { scrollMixins } from '@/mixins/scrollMixins'
export default {
  name: 'newsInfo',
  mixins: [scrollMixins],
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {
      key: 'unique',
      flag: false,
    }
  },
  computed: {
    getList() {
      return JSON.parse(JSON.stringify(this.list))
    },
  },
  methods: {
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      let fotBox = document.querySelector('.ul')
      let clientHeight = fotBox.clientHeight
      let offsetTop = fotBox.offsetTop
      if (scrollTop + clientHeight > offsetTop) {
        this.flag = true
      } else {
        this.flag = false
      }
    },
    handleCheckMore() {
      console.log('查看更多')
      this.$router.push({ name: 'newsInfoList' })
    },
    handleJump(item) {
      console.log('跳转新闻详情', item)
      if (item.contentType == 'SELF') {
        this.$router.push({
          path: '/newsInfoDetail',
          query: {
            code: item.newsCode,
          },
        })
      } else {
        window.open(item.linkUrl)
      }
    },
  },
  mounted() {},
}
</script>
<style scoped lang="less">
@themeColor: #00c4c9;
// 定义混入
.newsInfoBox {
  // width: 1220px;
  width: 100%;
  // padding: 0 18.23%;
  padding: 0 6%;
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  h2 {
    font-size: 1.625em;
    color: #333;
    font-weight: bold;
    text-align: center;
    // margin: 3.4375em 0;
    margin: 2.3em 0;
  }

  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .h5 {
      display: none;
    }

    .pc {
      width: 33.333%;
      height: 23.5em;
      padding-right: 1.25em;
      box-sizing: border-box;
      cursor: pointer;
      margin-bottom: 1.25em;
      display: block;
    }

    .item {
      width: 100%;
      height: 100%;
      background-color: #fff;
      border-radius: 0.5em;
      box-sizing: border-box;
      border: 1px solid #d8d8d8;
      overflow: hidden;
      // display: flex;
      // flex-flow: column wrap;
      animation: scale 1s;
      &.scaleImg {
        animation: scale 1.3s ease;
      }
      @keyframes scale {
        0% {
          transform: scale(0);
          opacity: 0;
        }
      }
      &:hover {
        box-shadow: 0.0625em 0.25em 0.625em 0.125em #ccc;
      }
      img {
        width: 100%;
        height: 15.125em;
        flex-shrink: 0;
        border-radius: 0.5em 0.5em 0 0;
        object-fit: cover;
      }

      .bottom {
        flex: 1;
        width: 100%;
        padding: 0.625em 0.75em 0.75em;
        box-sizing: border-box;
        background-color: #fff;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;

        h3 {
          font-size: 1.125em;
          color: #333;
          font-weight: bold;
          line-height: 1.4375em;
        }

        p {
          margin-top: 2.75em;
          color: #a1a1a1;
          font-size: 1em;
          font-weight: bold;
        }
      }
    }
  }

  .moreBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 0.4375em 1em;
    box-sizing: border-box;
    border-radius: 3.375em;
    border: 1px solid @themeColor;
    background-color: #fff;
    margin: 1.875em auto 3.125em;
    cursor: pointer;

    span {
      font-weight: bold;
      font-size: 1.25em;
      color: @themeColor;
      margin-right: 0.375em;
      letter-spacing: 0.125em;
    }

    .circle {
      width: 2.125em;
      height: 2.125em;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: @themeColor;

      img {
        width: 0.8125em;
        height: 0.8125em;
      }
    }
  }
}

// 移动端开始
@media screen and (max-width: 768px) {
  .newsInfoBox {
    width: 100%;
    padding: 0 10px;

    h2 {
      font-size: 16px;
      color: #333;
      font-weight: bold;
      text-align: center;
      margin: 18px 0;
    }

    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .pc {
        display: none;
      }

      .h5 {
        width: 100%;
        box-sizing: border-box;
        cursor: pointer;
        margin-bottom: 12px;
        display: block;
      }

      .item {
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 4px;
        box-sizing: border-box;
        border: 1px solid #d8d8d8;
        overflow: hidden;
        img {
          width: 100%;
          height: 215px;
          border-radius: 4px 4px 0 0;
          object-fit: cover;
        }

        .bottom {
          width: 100%;
          padding: 10px 10px 14px;
          box-sizing: border-box;
          background-color: #fff;

          h3 {
            font-size: 15px;
            color: #333;
            font-weight: bold;
          }

          p {
            margin-top: 15px;
            color: #a1a1a1;
            font-size: 13px;
            font-weight: bold;
          }
        }
      }
    }

    .moreBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      padding: 5px 13px;
      box-sizing: border-box;
      border-radius: 54px;
      border: 1px solid @themeColor;
      background-color: #fff;
      margin: 30px auto 50px;
      cursor: pointer;

      span {
        font-weight: bold;
        font-size: 14px;
        color: @themeColor;
        margin-right: 5px;
        letter-spacing: 1px;
      }

      .circle {
        width: 17px;
        height: 17px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: @themeColor;

        img {
          width: 8px;
          height: 8px;
        }
      }
    }
  }
}

// 移动端结束

// 平板开始
@media screen and (min-width: 768px) and (max-width: 992px) {
  .newsInfoBox {
    font-size: 14px;
    padding: 0 2%;
  }
}

// 平板结束

// 中等屏幕开始

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .newsInfoBox {
    font-size: 18px;
    padding: 0 3%;
  }
}

// 中等屏幕结束

// 大屏幕1200px - 1596px 开始
@media screen and (min-width: 1200px) and (max-width: 1596px) {
  .newsInfoBox {
    font-size: 20px;
    padding: 0 7%;
  }
}
// 大屏幕1200px - 1596px 结束

// 大屏幕1596px 开始
@media screen and (min-width: 1596px) and (max-width: 2200px) {
  .newsInfoBox {
    font-size: 20px;
    padding: 0 7%;
  }
}
// 大屏幕1596px 结束

// 大屏幕2200px 开始
@media screen and (min-width: 2200px) {
  .newsInfoBox {
    font-size: 20px;
    padding: 0 7%;
  }
}
// 大屏幕2200px 结束
</style>